<template>
    <div class="project-preview">
        <nuxt-link
            :to="mainLink"
            class="project-preview__block"
            :class="previewClass"
            :style="background"
            data-aos="custom-zoom-to"
            :data-aos-anchor="`#anchor-background-${index}`"
        >
            <div class="project-preview__shade" />
            <div class="project-preview__content">
                <h3 class="project-preview__title">{{ caseOrProject.title }}</h3>
                <div v-if="caseOrProject.featuredHTML" class="project-preview__featuredText" v-html="caseOrProject.featuredHTML" /> <!-- eslint-disable-line vue/no-v-html -->
            </div>
            <div
                class="project-preview__footer"
                :data-color="iconColor"
            >
                <div v-if="isDoen && iconColor" class="project-preview__footer__icon">
                    <icon>
                        <icon-theme-green v-if="iconColor === 'groen'" />
                        <icon-theme-social v-else-if="iconColor === 'sociaal'" />
                        <icon-theme-creative v-else-if="iconColor === 'creatief'" />
                    </icon>
                </div>
                <div class="project-preview__footer__title">
                    <h3>{{ caseOrProject.title }}</h3>
                </div>
                <div v-if="isDoen && programTitle" class="project-preview__footer__subtitle">
                    <h4>{{ programTitle }}</h4>
                </div>
            </div>
        </nuxt-link>
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import Icon from '~/patterns/atoms/icon/icon.vue';
import IconThemeGreen from '~/patterns/atoms/_icons/theme-green.vue';
import IconThemeSocial from '~/patterns/atoms/_icons/theme-social.vue';
import IconThemeCreative from '~/patterns/atoms/_icons/theme-creative.vue';

const TYPE_PROJECT = 'projects_projects_Entry',
    TYPE_CASE = 'case_case_Entry',
    TYPE_PORTFOLIO = 'portfolio_portfolio_Entry';

export default {
    components: {
        Icon,
        IconThemeGreen,
        IconThemeSocial,
        IconThemeCreative
    },
    props: {
        caseOrProject: {
            type: Object,
            required: true,
            default() { return {}; }
        },
        index: {
            type: Number,
            required: true,
            default: 0
        },
        previewClass: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            TYPE_CASE,
            TYPE_PROJECT,
            TYPE_PORTFOLIO
        };
    },
    computed: {
        type() {
            return get(this, 'caseOrProject.__typename');
        },
        mainLink() {
            switch (this.type) {
            case TYPE_CASE:
                return this.localePath({ name: 'wat-we-doen-program-slug', params: { program: this.programCategory.slug, slug: this.caseOrProject.slug } });
            case TYPE_PORTFOLIO:
                return this.localePath({ name: 'portfolio-slug', params: { slug: this.caseOrProject.slug } });
            case TYPE_PROJECT:
            default:
                return this.localePath({ name: 'projecten-slug', params: { slug: this.caseOrProject.slug } });
            }
        },
        featureImage() {
            return get(this, 'caseOrProject.featuredImage.0.url');
        },
        programCategory() {
            return get(this, 'caseOrProject.programCategory.0');
        },
        programTitle() {
            return get(this, 'programCategory.title', '');
        },
        themeCategory() {
            return get(this, 'programCategory.themeCategory.0', '');
        },
        iconColor() {
            return get(this, 'themeCategory.slug', '');
        },
        background() {
            if (this.featureImage) {
                return `background-image: url(${this.featureImage});`;
            }
            return '';
        },
        ...mapGetters('sites', {
            isDoen: 'isDoen'
        })
    }
};
</script>

<style lang="less" src="./project-preview.less"></style>
