<template>
    <div
        class="more-projects"
        data-aos="custom-zoom-to"
        data-aos-anchor="#anchor-background-reset"
    >
        <h3 class="more-projects__title">
            <i18n v-if="isParticipaties" path="project_preview.more_portfolio">
                <template v-slot:portfolio>
                    <nuxt-link :to="localePath({ name: 'portfolio-slug' })">{{ $t('project_preview.portfolio') }}</nuxt-link>
                </template>
            </i18n>
            <i18n v-else-if="isDoen" path="project_preview.more_partners">
                <template v-slot:partners>
                    <nuxt-link :to="localePath({ name: 'projecten-slug' })">{{ $t('project_preview.partners') }}</nuxt-link>
                </template>
            </i18n>
            <i18n v-else path="project_preview.more_partners">
                <template v-slot:partners>
                    <nuxt-link :to="localePath({ name: 'projecten-slug' })">{{ $t('project_preview.partners') }}</nuxt-link>
                </template>
            </i18n>
        </h3>
        <div v-if="isDoen" class="more-projects__icons">
            <icon class="more-projects__icons__green"><icon-theme-green /></icon>
            <icon class="more-projects__icons__social"><icon-theme-social /></icon>
            <icon class="more-projects__icons__creative"><icon-theme-creative /></icon>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '~/patterns/atoms/icon/icon.vue';
import IconThemeGreen from '~/patterns/atoms/_icons/theme-green.vue';
import IconThemeSocial from '~/patterns/atoms/_icons/theme-social.vue';
import IconThemeCreative from '~/patterns/atoms/_icons/theme-creative.vue';

export default {
    components: {
        Icon,
        IconThemeGreen,
        IconThemeSocial,
        IconThemeCreative
    },
    computed: {
        ...mapGetters('sites', {
            isDoen: 'isDoen',
            isParticipaties: 'isParticipaties',
            isBglf: 'isBglf'
        })
    }
};
</script>

<style src="./more-projects.less" lang="less"></style>
