<template>
    <header
        class="home-hero"
        :class="classes"
    >
        <div v-if="isBglf" class="home-hero__background-sticky">
            <div class="home-hero__background-sticky__stick" />
        </div>

        <template v-if="!isParticipaties && hasCasesOrProjects">
            <div class="home-hero__background-one" data-aos="custom-background-one" data-aos-anchor="#anchor-background-0" />
            <div v-if="casesOrProjects.length > 1" class="home-hero__background-two" data-aos="custom-background-two" data-aos-anchor="#anchor-background-1" />
            <div v-if="casesOrProjects.length > 2" class="home-hero__background-three" data-aos="custom-background-three" data-aos-anchor="#anchor-background-2" />
            <div class="home-hero__background-reset" data-aos="custom-background-reset" data-aos-anchor="#anchor-background-reset" />
        </template>

        <div class="home-hero__title">
            <h1 class="home-hero__title__header" :style="{ opacity: titleOpacity }">
                <template v-if="isBglf">Het VriendenLoterij Fonds ondersteunt voorlopers die zich inzetten voor een wereld waarin iedereen meedoet en waar ruimte is voor radicale verbeelding</template>
                <template v-else-if="isParticipaties">{{ $t('home.participaties_hero_text') }}</template>
                <template v-else>{{ $t('home.hero_text') }}</template>
            </h1>
        </div>

        <div v-if="hasCasesOrProjects" class="home-hero__previews">
            <div v-for="(item, index) in casesOrProjects" :key="index" class="home-hero__previews__item">
                <project-preview
                    v-if="item"
                    class="home-hero__previews__item__card"
                    :case-or-project="item"
                    :index="index"
                    preview-class="home-hero__previews__item__card__block"
                />
                <div :id="`anchor-background-${index}`" class="home-hero__previews__item__anchor" />
            </div>
            <div class="home-hero__previews__item home-hero__previews__more">
                <div class="home-hero__previews__item__card home-hero__previews__item__more">
                    <more-projects class="home-hero__previews__item__card__block" />
                </div>
                <div id="anchor-background-reset" class="home-hero__previews__item__anchor" />
            </div>
        </div>
    </header>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import { throttle } from 'lodash';

import ProjectPreview from '~/patterns/molecules/project-preview/project-preview';
import MoreProjects from '~/patterns/atoms/more-projects/more-projects.vue';

const offsetAnimation = 150;

export default {
    components: {
        ProjectPreview,
        MoreProjects
    },
    props: {
        casesOrProjects: {
            type: Array,
            required: false,
            default() { return []; }
        }
    },
    data() {
        return {
            currentScroll: 0,
            windowHeight: 0,
            stickyElements: null
        };
    },
    computed: {
        classes() {
            if (this.hasCasesOrProjects) {
                if (this.casesOrProjects.length === 1) {
                    return 'home-hero--preview home-hero--preview-one';
                } else if (this.casesOrProjects.length === 2) {
                    return 'home-hero--preview home-hero--preview-two';
                } else if (this.casesOrProjects.length >= 3) {
                    return 'home-hero--preview home-hero--preview-three';
                }
            }
            return '';
        },
        hasCasesOrProjects() {
            return this.casesOrProjects ? this.casesOrProjects.length : 0;
        },
        titleOpacity() {
            if (this.currentScroll && this.windowHeight) {
                return 1 - this.currentScroll / ((this.windowHeight / 2) - offsetAnimation);
            }
            return 1;
        },
        ...mapGetters('sites', {
            isDoen: 'isDoen',
            isParticipaties: 'isParticipaties',
            isBglf: 'isBglf'
        })
    },
    mounted() {
        window.addEventListener(
            'scroll',
            throttle(this.handleScroll, 100, {
                leading: true
            })
        );
    },
    destroyed() {
        window.removeEventListener(
            'scroll',
            this.handleScroll
        );
    },
    methods: {
        handleScroll() {
            this.currentScroll = window.pageYOffset || window.scrollY;
            this.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        }
    }
};
</script>

<style lang="less" src="./home-hero.less"></style>
