import { get } from 'lodash';
import { getCodeFromIsoOrLanguage } from '~/lang/locales';

export default {
    watch: {
        entry(value) {
            return setEntryForLocalization(this.$store, value);
        }
    },
    created() {
        // asyncData is called before destroyed, so we need to call setEntryForLocalization again,
        // and we can't rely on vue / nuxt waiting on created to finish, because vue / nuxt does
        // not await lifecycle hooks. So sadly
        if (process.client) {
            return setEntryForLocalization(
                this.$store,
                get(this, 'entry', null)
            );
        }
    },
    destroyed() {
        return setEntryForLocalization(this.$store, null);
    }
};

export function setEntryForLocalization(store, entry) {
    // Set the correct route params for nuxt-i18n to generate the translation link and meta.
    const localisations = get(entry, 'localized', null);

    if (localisations) {
        return store.dispatch('l10n/setLocalisations', localisations.map((localisation) => {
            return {
                language: getCodeFromIsoOrLanguage(localisation.language),
                uri: `/${localisation.uri}`
            };
        }));
    }

    return store.dispatch('l10n/setLocalisations', null);
}
