<template>
    <div>
        <home-hero :cases-or-projects="casesOrProjects" />
        <flexible-section v-if="entry" :sections="entry.flexibleSection" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isNull, get } from 'lodash';
import HomeHero from '~/patterns/organisms/home-hero/home-hero';
import FlexibleSection from '~/patterns/organisms/flexible-section/flexible-section';
import { HOME_URI } from '~/store/pages';
import seo from '~/mixins/seo';
import { setEntryForLocalization } from '~/mixins/entryLocalization';

export default {
    components: {
        HomeHero,
        FlexibleSection
    },
    mixins: [
        seo
    ],
    async asyncData(context) {
        // results are returned async via byUri getter.
        const result = await context.store.dispatch('pages/getHome');

        // We need to call setEntryForLocalization ourselves because mixins are not loaded yet, and the
        // server will return the result without waiting for async watchers.
        await setEntryForLocalization(context.store, null);

        // if the result of the call is null however, the page does not exist, so here we want to return a 404!
        if (isNull(result)) {
            return context.error({ statusCode: 404, message: 'error.message.404' });
        }
    },
    computed: {
        entry() {
            return this.byUri(HOME_URI)[0];
        },
        casesOrProjects() {
            return get(this, 'entry.featuredCaseOrProject', null);
        },
        ...mapState('i18n', ['locale']),
        ...mapGetters('pages', {
            byUri: 'getByUri'
        })
    }
};
</script>
